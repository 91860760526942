import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Navigation from './Navigation';

export default () => (
  <StaticQuery
    query={graphql`
      {
        allNavigationJson {
          edges {
            node {
              text
              path
              separator
              external
            }
          }
        }
      }
    `}
    render={({ allNavigationJson: { edges: links } }) => (
      <Navigation links={links} />
    )}
  />
);
