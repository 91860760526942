import cx from 'classnames';
import * as React from 'react';
import Helmet from 'react-helmet';

import favicon from '../../../static/assets/favicon.ico';
import Navigation from '../Navigation';

import 'prismjs/themes/prism.css';
import './index.scss';

interface MetaData {
  title: string;
  description: string;
}

interface Props {
  metaData: MetaData;
  isHomePage: boolean;
  children: React.ReactElement;
}

const Layout = ({ children, metaData, isHomePage }: Props) => (
  <div className={cx('main', { 'main--home': isHomePage })}>
    <Helmet
      title={metaData.title}
      meta={[
        {
          content: metaData.description,
          name: 'description',
        },
      ]}>
      <link rel="shortcut icon" href={favicon} />
      <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Montserrat|Quicksand:400,500" />
    </Helmet>
    <Navigation />
    <div className="content">{children}</div>
  </div>
);

export default Layout;
