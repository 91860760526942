import { Location } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';

export default (props) => (
  <Location>
    {({ location }) => {
      const isHomePage = location.pathname === '/';
      return (
        <StaticQuery
          query={graphql`
            {
              site {
                siteMetadata {
                  title
                  description
                }
              }
            }
          `}
          render={({ site: { siteMetadata } }) => (
            <Layout
              {...props}
              metaData={siteMetadata}
              isHomePage={isHomePage}
            />
          )}
        />
      );
    }}
  </Location>
);
