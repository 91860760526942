import cx from 'classnames';
import * as React from 'react';

import Icon from '../Icon';
import NavLink from './NavLink';

import './Navigation.scss';

export interface Node {
  separator?: boolean;
  external: boolean;
  path: string;
  text: string;
}

interface Props {
  links: Array<{ node: Node; }>;
}

class Navigation extends React.Component<Props> {
  public state = {
    isOpen: false,
  };

  public toggleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  public render() {
    const { links } = this.props;
    return (
      <div className="navigation">
        <div onClick={this.toggleClick}>
          <Icon icon="menu" />
        </div>
        <div className={cx('navigation-links', { open: this.state.isOpen })}>
          {links.map(
            ({ node }, i) =>
              node.separator ? (
                <span className="navigation-separator" key={`link-${i}`}>|</span>
              ) : (
                <NavLink {...node} key={`link-${i}`} />
              ),
          )}
        </div>
      </div>
    );
  }
}

export default Navigation;
