import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import * as React from 'react';
import { Node as Props } from './Navigation';

const NavLink = ({ external, path, text }: Props) =>
  external ? (
    <OutboundLink href={path} target="_blank">
      {text}
    </OutboundLink>
  ) : (
    <Link activeClassName="active" to={path}>
      {text}
    </Link>
  );

export default NavLink;
