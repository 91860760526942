import * as React from 'react';

import './Icon.scss';

/* tslint:disable:max-line-length */
const pathDict = {
  menu: 'M6 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z',
  tag: 'M10.605 0h-10.605v10.609l13.391 13.391 10.609-10.604-13.395-13.396zm-4.191 6.414c-.781.781-2.046.781-2.829.001-.781-.783-.781-2.048 0-2.829.782-.782 2.048-.781 2.829-.001.782.782.781 2.047 0 2.829z',
};
/* tslint:enable:max-line-length */

interface Props {
    icon: keyof typeof pathDict;
}

const Icon = ({ icon }: Props) => (
  <svg
    className={`icon icon--${icon}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <path d={pathDict[icon]} />
  </svg>
);

export default Icon;
